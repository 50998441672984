import { AppButton, AppTable } from 'components';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import api from 'services/api';

const TeamList = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [data, setData] = useState([]);
  const [page] = useState(
    searchParams.get('page') ? Number(searchParams.get('page')) : 1
  );
  const titles = ['Takım Adı', 'Takım Logosu', 'Teknik Direktör'];
  const [totalCount, setTotalCount] = useState(0);

  const onDelete = async id => {
    api.team.delete(id).then(() => {
      navigate(0);
    });
  };

  useEffect(() => {
    api.team
      .list(page)
      .then(async res => {
        setData(res.data.teams);
        setTotalCount(res.data.total);
      })
      .catch(error => console.log('error', error));
  }, []);

  return (
    <main className="w-full">
      <AppButton
        className="mb-5 ml-auto"
        isFullWidth={false}
        onClick={() => navigate('/team/add')}
      >
        Yeni Takım Ekle
      </AppButton>
      <AppTable
        titles={titles}
        rows={data}
        isEdit={true}
        isDelete={true}
        pageKey="team"
        pageNumber={page}
        onDelete={onDelete}
        totalCount={totalCount}
        hideRows="__v,_id,managerPhoto,createdDate,nameEn"
      />
    </main>
  );
};

export default TeamList;
