import React from 'react';
import { AuthLayout, DashboardLayout } from '../layouts';
import Home from './Home';
import Login from './Login';
import Page404 from './Page404';
import TeamList from './Teams/TeamList';
import AddTeam from './Teams/AddTeam';
import LeagueList from './Leagues/LeagueList';
import AddLeague from './Leagues/AddLeague';
import MatchList from './Matches/MatchList';
import ArchiveList from './Matches/ArchiveList';
import AddMatch from './Matches/AddMatch';
import Deploy from './Deploy';
import AddSite from './Sites/AddSite';
import SiteList from './Sites/SiteList';
import AddCountry from './Countries/AddCountry';
import CountryList from './Countries/CountryList';
import PaymentList from './Payments/PaymentList';
import AddPayment from './Payments/AddPayment';
import Ads from './Ads';
import AddAdsLink from './Ads/AddLink';
import AdsLinkList from './Ads/LinkList';
import GenerateImages from './GenerateImages';

const routes = [
  {
    path: '/',
    element: <DashboardLayout />,
    children: [
      {
        index: true,
        element: <Home />
      },
      {
        path: 'deploy',
        element: <Deploy />
      },
      {
        path: 'team',
        children: [
          {
            path: 'list',
            element: <TeamList />
          },
          {
            path: 'add',
            element: <AddTeam />
          }
        ]
      },
      {
        path: 'ads',
        children: [
          {
            path: '',
            element: <Ads />
          },
          {
            path: 'list',
            element: <AdsLinkList />
          },
          {
            path: 'add',
            element: <AddAdsLink />
          }
        ]
      },
      {
        path: 'generate-images',
        children: [
          {
            path: '',
            element: <GenerateImages />
          }
        ]
      },
      {
        path: 'league',
        children: [
          {
            path: 'list',
            element: <LeagueList />
          },
          {
            path: 'add',
            element: <AddLeague />
          }
        ]
      },
      {
        path: 'match',
        children: [
          {
            path: 'list',
            element: <MatchList />
          },
          {
            path: 'add',
            element: <AddMatch />
          },
          {
            path: 'archive',
            element: <ArchiveList />
          }
        ]
      },
      {
        path: 'site',
        children: [
          {
            path: 'list',
            element: <SiteList />
          },
          {
            path: 'add',
            element: <AddSite />
          }
        ]
      },
      {
        path: 'country',
        children: [
          {
            path: 'list',
            element: <CountryList />
          },
          {
            path: 'add',
            element: <AddCountry />
          }
        ]
      },
      {
        path: 'payment',
        children: [
          {
            path: 'list',
            element: <PaymentList />
          },
          {
            path: 'add',
            element: <AddPayment />
          }
        ]
      }
    ]
  },
  {
    path: '/auth',
    element: <AuthLayout />,
    children: [
      {
        path: 'login',
        element: <Login />
      }
    ]
  },
  {
    path: '*',
    element: <Page404 />
  }
];

export default routes;
