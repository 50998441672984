import React from 'react';
import { AppHeader, AppSidebar } from 'components';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import menu from 'constants/menu';

const DashboardLayout = () => {
  const location = useLocation();
  const page = menu.find(item => item.path === location.pathname);

  const PrivateRoute = ({ children }) => {
    const { user } = useAuth();

    if (!user) {
      return <Navigate to="/auth/login" replace={true} />;
    }

    return children;
  };

  return (
    <PrivateRoute>
      <div className="h-screen flex">
        <div className="flex w-1/6 border-r border-gray-200 px-8 py-10 bg-gray-900 border-gray-900">
          <AppSidebar activePage={page} />
        </div>
        <div className="flex flex-col w-5/6 px-8 py-4 max-h-screen overflow-y-scroll">
          <AppHeader pageName={page.name} />
          <Outlet />
        </div>
      </div>
    </PrivateRoute>
  );
};

export default DashboardLayout;
