export const adsApi = api => ({
  update(title, titleEn, status) {
    return api.post(`/ads/update`, {
      title,
      titleEn,
      status
    });
  },
  get() {
    return api.get(`/ads`);
  },
  createLink(name, nameEn, code, link) {
    return api.post('/ads/link/create', {
      name,
      nameEn,
      code,
      link
    });
  },
  linkList(page) {
    return api.get(`/ads/link/list/${page}`);
  },
  getLink(id) {
    return api.get(`/ads/link/${id}`);
  },
  updateLink(name, nameEn, code, link, id) {
    return api.post(`/ads/link/update/${id}`, {
      name,
      nameEn,
      code,
      link
    });
  },
  deleteLink(id) {
    return api.delete(`/ads/link/delete/${id}`);
  },
  generateImage(type, params) {
    return api.post(`/ads/generate-image/${type}`, params);
  }
});
