export const getLogoId = logo => {
  return logo.split('.com/')[1];
};

export const getCoverUrl = (
  homeTeam,
  awayTeam,
  league,
  withoutTitle = false,
  isBig = false
) => {
  const homeTeamName = withoutTitle ? '' : homeTeam.label;
  const awayTeamName = withoutTitle ? '' : awayTeam.label;
  const SITE_URL = 'https://www.mactoplantisi.com';

  const params = `hl=${getLogoId(
    homeTeam.logo
  )}&ht=${homeTeamName}&al=${getLogoId(awayTeam.logo)}&at=${awayTeamName}&l=${
    league.slugEn
  }&big=${isBig}`;

  return `${SITE_URL}/api/og?${params}`;
};

export const getTwitterTodayMatchUrl = (
  homeTeam,
  awayTeam,
  homeSummary,
  awaySummary,
  lang = 'tr'
) => {
  const homeTeamName = lang === 'tr' ? homeTeam.label : homeTeam.labelEn;
  const awayTeamName = lang === 'tr' ? awayTeam.label : awayTeam.labelEn;

  const SITE_URL = 'https://www.mactoplantisi.com';

  const params = new URLSearchParams({
    hl: getLogoId(homeTeam.logo),
    ht: homeTeamName,
    al: getLogoId(awayTeam.logo),
    at: awayTeamName,
    hs: homeSummary,
    as: awaySummary,
    hm: homeTeam.manager,
    am: awayTeam.manager,
    hmp: getLogoId(homeTeam.managerPhoto),
    amp: getLogoId(awayTeam.managerPhoto),
    lang
  });

  return `${SITE_URL}/api/twitter-today-match?${params}`;
};

export const getTwitterTodayCommentUrl = (
  homeTeam,
  awayTeam,
  homeSummary,
  awaySummary,
  lang = 'tr',
  isHome
) => {
  const homeTeamName = lang === 'tr' ? homeTeam.label : homeTeam.labelEn;
  const awayTeamName = lang === 'tr' ? awayTeam.label : awayTeam.labelEn;
  const summary = isHome ? homeSummary : awaySummary;
  const commentTeam = isHome ? homeTeam : awayTeam;

  const SITE_URL = 'https://www.mactoplantisi.com';

  const params = new URLSearchParams({
    hl: getLogoId(homeTeam.logo),
    ht: homeTeamName,
    al: getLogoId(awayTeam.logo),
    at: awayTeamName,
    s: summary,
    m: commentTeam.manager,
    mp: getLogoId(commentTeam.managerPhoto),
    lang,
    ih: isHome.toString()
  });

  return `${SITE_URL}/api/twitter-today-comment?${params}`;
};

export const getTwitterWhatHappenedUrl = (
  homeTeam,
  awayTeam,
  homeSummary,
  awaySummary,
  lang = 'tr',
  isHome,
  score
) => {
  const homeTeamName = lang === 'tr' ? homeTeam.label : homeTeam.labelEn;
  const awayTeamName = lang === 'tr' ? awayTeam.label : awayTeam.labelEn;
  const summary = isHome ? homeSummary : awaySummary;
  const commentTeam = isHome ? homeTeam : awayTeam;

  const SITE_URL = 'https://www.mactoplantisi.com';

  const params = new URLSearchParams({
    hl: getLogoId(homeTeam.logo),
    ht: homeTeamName,
    al: getLogoId(awayTeam.logo),
    at: awayTeamName,
    s: summary,
    m: commentTeam.manager,
    mp: getLogoId(commentTeam.managerPhoto),
    lang,
    ih: isHome.toString(),
    sc: score
  });

  return `${SITE_URL}/api/twitter-what-happened?${params}`;
};

export const getTwitterMatchesUrl = (matches, lang = 'tr') => {
  const params = new URLSearchParams({
    lang
  });

  for (let i = 0; i < matches.length; i++) {
    const match = matches[i];
    const homeTeamName =
      lang === 'tr' ? match.homeTeam.name : match.homeTeam.nameEn;
    const awayTeamName =
      lang === 'tr' ? match.awayTeam.name : match.awayTeam.nameEn;
    const homeTeamLogo = getLogoId(match.homeTeam.logo);
    const awayTeamLogo = getLogoId(match.awayTeam.logo);
    const matchMinute =
      new Date(match.matchDate).getMinutes() === 0
        ? '00'
        : new Date(match.matchDate).getMinutes().toString();
    const matchTime =
      new Date(match.matchDate).getHours().toString() + ':' + matchMinute;
    const leagueLogo = getLogoId(match.league.logo);

    params.append(`ht${i}`, homeTeamName);
    params.append(`at${i}`, awayTeamName);
    params.append(`hl${i}`, homeTeamLogo);
    params.append(`al${i}`, awayTeamLogo);
    params.append(`mt${i}`, matchTime);
    params.append(`ll${i}`, leagueLogo);
  }

  const SITE_URL = 'https://www.mactoplantisi.com';

  return `${SITE_URL}/api/twitter-today-matches?${params}`;
};
