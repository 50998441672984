import React, { useEffect, useMemo } from 'react';
import { AppButton, AppInput, AppSelect } from 'components';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { t } from 'locales';
import api from 'services/api';

export default function Index() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});

  const adsStatus = [
    {
      label: 'Site Yönlendir',
      value: 'redirect'
    },
    {
      label: 'Listeye Gönder',
      value: 'list'
    }
  ];

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    reset
  } = useForm({
    defaultValues: useMemo(() => {
      return data;
    }, [data])
  });

  useEffect(() => {
    api.ads.get().then(async res => {
      res.data.status = adsStatus.find(
        status => status.value === res.data.status
      );
      setData(res.data);
      reset(res.data);
    });
  }, []);

  const onSubmit = async submitData => {
    setIsLoading(true);
    await api.ads
      .update(submitData.title, submitData.titleEn, submitData.status.value)
      .catch(error => console.log('error', error));
    setIsLoading(false);
  };

  return (
    <main className="w-2/5 flex flex-col items-center mx-auto">
      <div className="text-3xl font-bold text-gray-700 mb-4 text-center">
        Reklam Ayarları
      </div>
      <div className="text-lg text-gray-700 mb-8 text-center">
        Bu sayfadan reklam ayarlarınızı düzenleyebilirsiniz. Reklam ayarlarını
        düzenledikten sonra deploy almanıza <b>gerek yoktur.</b>.
      </div>
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
        <AppInput
          label="Reklam Başlığı"
          name="title"
          // @ts-ignore
          error={errors.title}
          rules={{ required: true, minLength: 3 }}
          register={register}
          placeholder="Reklam Başlığı"
        />
        <AppInput
          label="Reklam Açıklaması - İngilizce"
          name="titleEn"
          // @ts-ignore
          error={errors.titleEn}
          rules={{ required: true, minLength: 3 }}
          register={register}
          placeholder="Reklam Açıklaması - İngilizce"
        />
        <AppSelect
          options={adsStatus}
          label="Reklam Durumu"
          name="status"
          error={errors.status}
          rules={{ required: true }}
          control={control}
          placeholder="Reklam Durumu"
        />
        <AppButton type="submit" className="mt-5" isLoading={isLoading}>
          {t('_general.save')}
        </AppButton>
      </form>
    </main>
  );
}
