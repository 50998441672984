import React from 'react';
import { AppButton, AppSelect, MaskInput } from 'components';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import api from 'services/api';
import { datePattern } from '../../constants/patterns';
import { getTwitterMatchesUrl } from '../../utils/image';

export default function GenerateImages() {
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
    control
  } = useForm({});

  const onSubmit = async submitData => {
    setIsLoading(true);
    const getDate = submitData.matchDate.split('/').reverse().join('-');
    const matchDate = new Date(getDate);
    const lang = submitData.lang.value;
    await api.ads
      .generateImage('today-matches', {
        matchDate
      })
      .then(res => {
        const matches = res.data;
        const url = getTwitterMatchesUrl(matches, lang);
        window.open(url, '_blank');
      });
    setIsLoading(false);
  };

  return (
    <main className="w-2/5 flex flex-col items-center mx-auto">
      <div className="text-3xl font-bold text-gray-700 mb-4 text-center">
        Görsel Üret
      </div>
      <div className="text-lg text-gray-700 text-center">
        Reklam ve Twitter görsellerini üretmek için bu sayfayı
        kullanabilirsiniz.
      </div>
      <div className="border-b border-gray-200 my-8 w-full"></div>
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
        <div className="text-lg text-gray-700 mb-4 font-bold">
          Günün Maçlarını Üret
        </div>
        <MaskInput
          label="Tarih"
          mask="99/99/9999"
          maskPlaceholder="GG/AA/YYYY"
          name="matchDate"
          // @ts-ignore
          error={errors.matchDate}
          rules={{ required: true, pattern: datePattern }}
          register={register}
          placeholder="GG/AA/YYYY"
        />
        <AppSelect
          options={[
            {
              label: 'Türkçe',
              value: 'tr'
            },
            {
              label: 'İngilizce',
              value: 'en'
            }
          ]}
          label="Dil"
          name="lang"
          error={errors.lang}
          rules={{ required: true }}
          control={control}
          placeholder="Dil"
        />
        <AppButton type="submit" className="mt-5" isLoading={isLoading}>
          Üret
        </AppButton>
      </form>
    </main>
  );
}
