import { AppTable } from 'components';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import api from 'services/api';

const ArchiveList = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [data, setData] = useState([]);
  const [page] = useState(
    searchParams.get('page') ? Number(searchParams.get('page')) : 1
  );
  const titles = ['LİG ADI', 'EV SAHİBİ', 'DEPLASMAN', 'MAÇ TARİHİ'];

  const onDelete = async id => {
    api.match.delete(id).then(() => {
      navigate(0);
    });
  };

  const onRemoveArchive = async id => {
    api.match.changeArchive(id, false).then(() => {
      navigate(0);
    });
  };

  useEffect(() => {
    api.match
      .archive(page)
      .then(async res => {
        setData(res.data);
      })
      .catch(error => console.log('error', error));
  }, []);

  return (
    <main className="w-full">
      <AppTable
        titles={titles}
        rows={data}
        isEdit={false}
        isDelete={true}
        isRemoveArchive={true}
        pageKey="match"
        pageNumber={page}
        onDelete={onDelete}
        onRemoveArchive={onRemoveArchive}
        hideRows="__v,_id,slug,createdDate,homeContent,awayContent,description,cover,awayScore,homeScore,updatedAt,isFeaturedMatch,isShowSlider,title,homeHighlight,awayHighlight,createdAt,archived,isShowHome,titleEn,homeHighlightEn,awayHighlightEn,homeContentEn,awayContentEn,descriptionEn,slugEn,nameEn,homeOrder,sliderOrder,featuredMatchOrder,site,siteContent,siteContentEn,isPublished,name"
      />
    </main>
  );
};

export default ArchiveList;
