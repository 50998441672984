import { Check, Close } from '@mui/icons-material';
import React from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { dateToString, timeToString } from 'utils/date';

const AppTable = ({
  titles,
  rows,
  isEdit = false,
  isDelete = false,
  isSendArchive = false,
  isRemoveArchive = false,
  onDelete,
  onSendArchive,
  onRemoveArchive,
  pageKey,
  pageNumber,
  hideRows = '',
  pageItems = 10,
  totalCount
}) => {
  const [, setSearchParams] = useSearchParams();
  const getValue = value => {
    if (typeof value === 'string') {
      if (value.substring(0, 4) === 'http') {
        if (value.includes('youtube')) {
          return <a href={value}>{value}</a>;
        }

        if (value.includes('ucarecdn')) {
          return <img src={value} className="h-12" />;
        }
      }

      if (!isNaN(new Date(value).getDate()) && value.length > 15) {
        return `${dateToString(value)} ${timeToString(value)}`;
      }

      return value;
    }

    if (typeof value === 'boolean') {
      return value ? <Check /> : <Close />;
    }

    if (typeof value === 'number') {
      return value;
    }

    return value.name;
  };

  const checkValue = key => {
    const splitHiddenRows = hideRows.split(',');
    const getKey = typeof key === 'string' ? key : key.name;
    const findRow = splitHiddenRows.find(row => getKey === row.trim());
    if (findRow) {
      return false;
    }

    return true;
  };

  const confirmDelete = id => {
    if (window.confirm('Silme işlemi geri alınamaz. Onaylıyor musunuz?')) {
      onDelete(id);
    }
  };

  const archive = id => {
    if (
      window.confirm(
        isSendArchive
          ? 'Bu içeriği arşive göndermek istiyor musunuz?'
          : 'Bu içeriği arşivden çıkarmak istiyor musunuz?'
      )
    ) {
      isSendArchive ? onSendArchive(id) : onRemoveArchive(id);
    }
  };

  const totalPage = Math.ceil(totalCount / pageItems);

  return (
    <>
      <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
        {rows && rows.length > 0 ? (
          <table className="w-full text-sm text-left text-gray-500">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50">
              <tr>
                <th scope="col" className="py-3 px-6">
                  #
                </th>
                {titles.map((title, index) => (
                  <th scope="col" className="py-3 px-6" key={`title${index}`}>
                    {title}
                  </th>
                ))}
                {isEdit && (
                  <th scope="col" className="py-3 px-6">
                    Düzenle
                  </th>
                )}
                {(isSendArchive || isRemoveArchive) && (
                  <th scope="col" className="py-3 px-6">
                    ARŞİV
                  </th>
                )}
                {isDelete && (
                  <th scope="col" className="py-3 px-6">
                    SİL
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {rows.map((row, index) => (
                <tr className="bg-white border-b" key={`tr${index}`}>
                  <td className="px-6 py-4" key={`index${index}`}>
                    {index + 1 + (pageNumber - 1) * pageItems}
                  </td>
                  {Object.keys(row).map(
                    (key, index) =>
                      checkValue(key) && (
                        <td className="py-3 px-6 " key={`row${index}`}>
                          {getValue(row[key])}
                        </td>
                      )
                  )}
                  {isEdit && (
                    <td className="py-3 px-6">
                      <Link
                        to={`/${pageKey}/add?id=${row._id}`}
                        className="px-3 py-2 text-xs font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 cursor-pointer"
                      >
                        Düzenle
                      </Link>
                    </td>
                  )}
                  {(isSendArchive || isRemoveArchive) && (
                    <td className="py-3 px-6">
                      <button
                        onClick={() => archive(row._id)}
                        className="px-3 py-2 text-xs font-medium text-center text-white bg-yellow-600 rounded-lg hover:bg-yellow-700 focus:ring-4 focus:outline-none focus:ring-yellow-200 cursor-pointer"
                      >
                        {isSendArchive ? 'ARŞİVE GÖNDER' : 'ARŞİVDEN ÇIKAR'}
                      </button>
                    </td>
                  )}
                  {isDelete && (
                    <td className="py-3 px-6">
                      <button
                        onClick={() => confirmDelete(row._id)}
                        className="px-3 py-2 text-xs font-medium text-center text-white bg-red-700 rounded-lg hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 cursor-pointer"
                      >
                        SİL
                      </button>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="text-center px-10 py-10 text-xl text-gray-500">
            Herhangi bir data bulunamadı.
          </div>
        )}
      </div>
      {totalCount ? (
        <nav
          aria-label="navigation"
          className="mt-4 flex align-center justify-center"
        >
          <ul className="flex items-center -space-x-px h-8 text-sm">
            <li>
              <button
                // @ts-ignore
                onClick={() => setSearchParams({ page: pageNumber - 1 })}
                disabled={pageNumber === 1}
                className="flex items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                <span className="sr-only">Previous</span>
                <svg
                  className="w-2.5 h-2.5"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 6 10"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 1 1 5l4 4"
                  />
                </svg>
              </button>
            </li>
            {Array.from(Array(totalPage).keys()).map(index => (
              <li key={`page${index}`}>
                <button
                  onClick={() => setSearchParams({ page: index + 1 })}
                  className={`flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 ${
                    pageNumber === index + 1
                      ? 'bg-blue-50 border-blue-300 text-blue-600'
                      : ''
                  }`}
                >
                  {index + 1}
                </button>
              </li>
            ))}
            <li>
              <button
                onClick={() => setSearchParams({ page: pageNumber + 1 })}
                disabled={pageNumber === totalPage}
                className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                <span className="sr-only">Next</span>
                <svg
                  className="w-2.5 h-2.5"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 6 10"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 9 4-4-4-4"
                  />
                </svg>
              </button>
            </li>
          </ul>
        </nav>
      ) : (
        <div className="flex align-middle mt-4 justify-between">
          {pageNumber > 1 ? (
            <button
              // @ts-ignore
              onClick={() => setSearchParams({ page: pageNumber - 1 })}
              className="inline-flex items-center py-2 px-4 mr-3 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
            >
              <svg
                aria-hidden="true"
                className="mr-2 w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
              Geri
            </button>
          ) : (
            <div></div>
          )}
          {rows && rows.length === pageItems ? (
            <button
              onClick={() => setSearchParams({ page: pageNumber + 1 })}
              className="inline-flex items-center py-2 px-4 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
            >
              İleri
              <svg
                aria-hidden="true"
                className="ml-2 w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>
          ) : (
            <div></div>
          )}
        </div>
      )}
    </>
  );
};

export default AppTable;
