import { AppButton, AppTable } from 'components';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import api from 'services/api';

const LeagueList = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [data, setData] = useState([]);
  const [page] = useState(
    searchParams.get('page') ? Number(searchParams.get('page')) : 1
  );
  const titles = ['ÜLKE ADI', 'ÜLKE KODU'];

  const onDelete = async id => {
    api.country.delete(id).then(() => {
      navigate(0);
    });
  };

  useEffect(() => {
    api.country
      .list(page)
      .then(async res => {
        setData(res.data);
      })
      .catch(error => console.log('error', error));
  }, []);

  return (
    <main className="w-full">
      <AppButton
        className="mb-5 ml-auto"
        isFullWidth={false}
        onClick={() => navigate('/country/add')}
      >
        Yeni Ülke Ekle
      </AppButton>
      <AppTable
        titles={titles}
        rows={data}
        isEdit={true}
        isDelete={true}
        pageKey="country"
        pageNumber={page}
        onDelete={onDelete}
        pageItems={20}
        hideRows="__v,_id,createdAt,updatedAt,nameEn,site_1,site_2,site_3,site_4,site_5,site_6,site_7,site_8,site_9,site_10"
      />
    </main>
  );
};

export default LeagueList;
