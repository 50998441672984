import React, { useEffect, useMemo } from 'react';
import { AppButton, AppInput } from 'components';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { t } from 'locales';
import api from 'services/api';
import { useNavigate, useSearchParams } from 'react-router-dom';

export default function AddPayment() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [data, setData] = useState({});
  const id = searchParams.get('id');
  const isEdit = !!id;

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm({
    defaultValues: useMemo(() => {
      return data;
    }, [data])
  });

  useEffect(() => {
    if (isEdit) {
      api.ads.getLink(id).then(async res => {
        setData(res.data);
        reset(res.data);
      });
    }
  }, []);

  const onSubmit = async submitData => {
    setIsLoading(true);
    await api.ads[isEdit ? 'updateLink' : 'createLink'](
      submitData.name,
      submitData.nameEn,
      submitData.code,
      submitData.link,
      id
    )
      .then(async () => {
        navigate('/ads/list');
      })
      .catch(error => console.log('error', error));
    setIsLoading(false);
  };

  return (
    <main className="w-2/5 flex flex-col items-center mx-auto">
      <div className="text-3xl font-bold text-gray-700 mb-4 text-center">
        Yeni Reklam Linki Ekle
      </div>
      <div className="text-lg text-gray-700 mb-8 text-center">
        Yeni reklam linki için aşağıdaki formu doldurunuz.
      </div>
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
        <AppInput
          label="Ülke Adı"
          name="name"
          // @ts-ignore
          error={errors.name}
          rules={{}}
          register={register}
          placeholder="Ülke Adı"
        />
        <AppInput
          label="Ülke Adı (İngilizce)"
          name="nameEn"
          // @ts-ignore
          error={errors.nameEn}
          rules={{}}
          register={register}
          placeholder="Ülke Adı (İngilizce)"
        />
        <AppInput
          label="Ülke Kodu"
          name="code"
          // @ts-ignore
          error={errors.code}
          rules={{}}
          register={register}
          placeholder="Ülke Kodu"
        />
        <AppInput
          label="Reklam Linki"
          name="link"
          // @ts-ignore
          error={errors.name}
          rules={{ required: true }}
          register={register}
          placeholder="Reklam Linki"
        />
        <AppButton type="submit" className="mt-5" isLoading={isLoading}>
          {t('_general.save')}
        </AppButton>
      </form>
    </main>
  );
}
