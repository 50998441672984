import { apiConfig } from 'config';
import {
  authApi,
  userApi,
  teamApi,
  leagueApi,
  matchApi,
  siteAPi,
  countryApi,
  paymentApi,
  adsApi
} from 'services';

const api = apiConfig;

api.auth = authApi(api);
api.user = userApi(api);
api.team = teamApi(api);
api.league = leagueApi(api);
api.match = matchApi(api);
api.site = siteAPi(api);
api.country = countryApi(api);
api.payment = paymentApi(api);
api.ads = adsApi(api);

export default api;
