import {
  EmojiEvents,
  GroupsOutlined,
  HomeOutlined,
  RocketLaunchOutlined,
  SportsSoccer,
  Archive,
  Language,
  LocationOn,
  CreditCard,
  CenterFocusStrong,
  WebAsset,
  Filter
} from '@mui/icons-material';

export default [
  {
    name: 'Anasayfa',
    Icon: HomeOutlined,
    path: '/',
    mainPath: '/',
    isVisible: true
  },
  {
    name: 'Maçlar',
    Icon: SportsSoccer,
    mainPath: '/match/list',
    path: '/match/list',
    isVisible: true
  },
  {
    name: 'Maç Ekle',
    Icon: SportsSoccer,
    path: '/match/add',
    mainPath: '/match/list',
    isVisible: false
  },
  {
    name: 'Takımlar',
    Icon: GroupsOutlined,
    path: '/team/list',
    mainPath: '/team/list',
    isVisible: true
  },
  {
    name: 'Takım Ekle',
    Icon: GroupsOutlined,
    path: '/team/add',
    mainPath: '/team/list',
    isVisible: false
  },
  {
    name: 'Ligler',
    Icon: EmojiEvents,
    path: '/league/list',
    mainPath: '/league/list',
    isVisible: true
  },
  {
    name: 'Lig Ekle',
    Icon: EmojiEvents,
    path: '/league/add',
    mainPath: '/league/list',
    isVisible: false
  },
  {
    name: 'Siteler',
    Icon: Language,
    path: '/site/list',
    mainPath: '/site/list',
    isVisible: true
  },
  {
    name: 'Site Ekle',
    Icon: Language,
    path: '/site/add',
    mainPath: '/site/list',
    isVisible: false
  },
  {
    name: 'Ülkeler',
    Icon: LocationOn,
    path: '/country/list',
    mainPath: '/country/list',
    isVisible: true
  },
  {
    name: 'Ülke Ekle',
    Icon: LocationOn,
    path: '/country/add',
    mainPath: '/country/list',
    isVisible: false
  },
  {
    name: 'Görsel Üret',
    Icon: Filter,
    path: '/generate-images',
    mainPath: '/generate-images',
    isVisible: true
  },
  {
    name: 'Reklam',
    Icon: CenterFocusStrong,
    path: '/ads',
    mainPath: '/ads',
    isVisible: true
  },
  {
    name: 'Reklam Linkleri',
    Icon: WebAsset,
    path: '/ads/list',
    mainPath: '/ads/list',
    isVisible: true
  },
  {
    name: 'Reklam Linki Ekle',
    Icon: WebAsset,
    path: '/ads/add',
    mainPath: '/ads/list',
    isVisible: false
  },
  {
    name: 'Ödeme Yöntemleri',
    Icon: CreditCard,
    path: '/payment/list',
    mainPath: '/payment/list',
    isVisible: true
  },
  {
    name: 'Ödeme Yöntemi Ekle',
    Icon: CreditCard,
    path: '/payment/add',
    mainPath: '/payment/list',
    isVisible: false
  },
  {
    name: 'Arşiv',
    Icon: Archive,
    path: '/match/archive',
    mainPath: '/match/archive',
    isVisible: true
  },
  {
    name: 'Deploy',
    Icon: RocketLaunchOutlined,
    path: '/deploy',
    mainPath: '/deploy',
    isVisible: true
  }
];
