export const teamApi = api => ({
  create(name, nameEn, logo, manager, managerPhoto) {
    return api.post('/team/create', {
      name,
      nameEn,
      logo,
      manager,
      managerPhoto
    });
  },
  list(page) {
    return api.get(`/team/list/${page}`);
  },
  get(id) {
    return api.get(`/team/${id}`);
  },
  update(name, nameEn, logo, manager, managerPhoto, id) {
    return api.post(`/team/update/${id}`, {
      name,
      nameEn,
      logo,
      manager,
      managerPhoto
    });
  },
  delete(id) {
    return api.delete(`/team/delete/${id}`);
  }
});
