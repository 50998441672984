import React, { useEffect, useMemo } from 'react';
import {
  AppButton,
  AppCheckbox,
  AppEditor,
  AppInput,
  AppSelect,
  AppFile,
  MaskInput
} from 'components';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { t } from 'locales';
import api from 'services/api';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { convertToSlug } from 'utils/slug';
import { datePattern, timePattern } from 'constants/patterns';
import { dateToString, timeToString } from 'utils/date';
import {
  getCoverUrl,
  getTwitterTodayCommentUrl,
  getTwitterTodayMatchUrl,
  getTwitterWhatHappenedUrl
} from '../../utils/image';

export default function AddMatch() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [data, setData] = useState({});
  const id = searchParams.get('id');
  const isEdit = !!id;
  const [leagues, setLeagues] = useState([]);
  const [teams, setTeams] = useState([]);
  const [sites, setSites] = useState([]);
  const [cover, setCover] = useState();

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    reset,
    watch
  } = useForm({
    defaultValues: useMemo(() => {
      return data;
    }, [data])
  });

  useEffect(() => {
    const init = async () => {
      const teamsData = [];
      const leaguesData = [];
      await api.league.list(0).then(async res => {
        res.data.map(league => {
          leaguesData.push({
            value: league._id,
            label: league.name,
            slugEn: league.slugEn
          });
        });
        setLeagues(leaguesData);
      });
      await api.team.list(0).then(async res => {
        res.data.teams.map(team => {
          teamsData.push({
            value: team._id,
            label: team.name,
            logo: team.logo,
            manager: team.manager,
            managerPhoto: team.managerPhoto,
            labelEn: team.nameEn
          });
        });
        setTeams(teamsData);
      });
      const sitesData = [];
      await api.site.list(0).then(async res => {
        res.data.map(site => {
          sitesData.push({ value: site._id, label: site.name });
        });
        setSites(sitesData);
      });
      if (isEdit) {
        await api.match.get(id).then(async res => {
          const data = res.data;
          const editData = {
            awayContent: data.awayContent,
            awayContentEn: data.awayContentEn,
            homeContent: data.homeContent,
            homeContentEn: data.homeContentEn,
            awayTeam: teamsData.find(team => team.value === data.awayTeam),
            homeTeam: teamsData.find(team => team.value === data.homeTeam),
            isShowHome: data.isShowHome,
            league: leaguesData.find(league => league.value === data.league),
            matchDate: dateToString(data.matchDate),
            matchTime: timeToString(data.matchDate),
            title: data.title,
            titleEn: data.titleEn,
            description: data.description,
            descriptionEn: data.descriptionEn,
            isShowSlider: data.isShowSlider,
            isFeaturedMatch: data.isFeaturedMatch,
            homeOrder: data.homeOrder,
            sliderOrder: data.sliderOrder,
            featuredMatchOrder: data.featuredMatchOrder,
            site: sitesData.find(site => site.value === data.site),
            siteContent: data.siteContent,
            siteContentEn: data.siteContentEn,
            isPublished: data.isPublished,
            homeScore: data.homeScore,
            awayScore: data.awayScore,
            homeSummary: data.homeSummary,
            homeSummaryEn: data.homeSummaryEn,
            awaySummary: data.awaySummary,
            awaySummaryEn: data.awaySummaryEn,
            isEditorChoice: data.isEditorChoice
          };
          setCover(data.cover);
          setData(editData);
          reset(editData);
        });
      }
    };
    init();
  }, []);

  const onSubmit = async submitData => {
    setIsLoading(true);
    const getDate = submitData.matchDate.split('/').reverse().join('-');
    await api.match[isEdit ? 'update' : 'create'](
      submitData.league.value,
      submitData.homeTeam.value,
      submitData.awayTeam.value,
      new Date(`${getDate} ${submitData.matchTime}`),
      submitData.homeContent,
      submitData.homeContentEn,
      submitData.awayContent,
      submitData.awayContentEn,
      convertToSlug(
        `${submitData.title} ${submitData.matchDate.replaceAll('/', '')}`
      ),
      convertToSlug(
        `${submitData.titleEn} ${submitData.matchDate.replaceAll('/', '')}`
      ),
      submitData.isShowHome,
      submitData.title,
      submitData.titleEn,
      submitData.description,
      submitData.descriptionEn,
      cover,
      submitData.isShowSlider,
      submitData.isFeaturedMatch,
      submitData.homeOrder,
      submitData.sliderOrder,
      submitData.featuredMatchOrder,
      submitData.site?.value,
      submitData.siteContent,
      submitData.siteContentEn,
      submitData.isPublished,
      submitData.homeScore,
      submitData.awayScore,
      submitData.homeSummary,
      submitData.homeSummaryEn,
      submitData.awaySummary,
      submitData.awaySummaryEn,
      submitData.isEditorChoice,
      id
    )
      .then(async () => {
        navigate('/match/list');
      })
      .catch(error => console.log('error', error));
    setIsLoading(false);
  };

  const generateCover = async () => {
    if (!watch('homeTeam') || !watch('awayTeam')) return;
    const homeTeam = teams.find(team => team.value === watch('homeTeam').value);
    const awayTeam = teams.find(team => team.value === watch('awayTeam').value);
    const league = leagues.find(
      league => league.value === watch('league').value
    );

    const coverUrl = getCoverUrl(homeTeam, awayTeam, league, false, true);

    window.open(coverUrl, '_blank');
  };

  const generateTwitterTodayMatch = async (lang = 'tr') => {
    if (
      !watch('homeTeam') ||
      !watch('awayTeam') ||
      !watch('homeSummary') ||
      !watch('awaySummary')
    )
      return;

    const homeTeam = teams.find(team => team.value === watch('homeTeam').value);
    const awayTeam = teams.find(team => team.value === watch('awayTeam').value);
    const homeSummary =
      lang === 'tr' ? watch('homeSummary') : watch('homeSummaryEn');
    const awaySummary =
      lang === 'tr' ? watch('awaySummary') : watch('awaySummaryEn');

    const coverUrl = getTwitterTodayMatchUrl(
      homeTeam,
      awayTeam,
      homeSummary,
      awaySummary,
      lang
    );

    window.open(coverUrl, '_blank');
  };

  const generateTwitterTodayComment = async (lang = 'tr', isHome) => {
    if (
      !watch('homeTeam') ||
      !watch('awayTeam') ||
      !watch('homeSummary') ||
      !watch('awaySummary')
    )
      return;

    const homeTeam = teams.find(team => team.value === watch('homeTeam').value);
    const awayTeam = teams.find(team => team.value === watch('awayTeam').value);
    const homeSummary =
      lang === 'tr' ? watch('homeSummary') : watch('homeSummaryEn');
    const awaySummary =
      lang === 'tr' ? watch('awaySummary') : watch('awaySummaryEn');

    const coverUrl = getTwitterTodayCommentUrl(
      homeTeam,
      awayTeam,
      homeSummary,
      awaySummary,
      lang,
      isHome
    );

    window.open(coverUrl, '_blank');
  };

  const generateTwitterWhatHappened = async (lang = 'tr', isHome) => {
    if (lang === 'en') return;
    if (
      !watch('homeTeam') ||
      !watch('awayTeam') ||
      !watch('homeSummary') ||
      !watch('awaySummary')
    )
      return;

    const homeTeam = teams.find(team => team.value === watch('homeTeam').value);
    const awayTeam = teams.find(team => team.value === watch('awayTeam').value);
    const homeSummary =
      lang === 'tr' ? watch('homeSummary') : watch('homeSummaryEn');
    const awaySummary =
      lang === 'tr' ? watch('awaySummary') : watch('awaySummaryEn');
    const score = `${watch('homeScore')}-${watch('awayScore')}`;

    const coverUrl = getTwitterWhatHappenedUrl(
      homeTeam,
      awayTeam,
      homeSummary,
      awaySummary,
      lang,
      isHome,
      score
    );

    window.open(coverUrl, '_blank');
  };

  return (
    <main className="w-2/5 flex flex-col items-center mx-auto">
      <div className="text-3xl font-bold text-gray-700 mb-4 text-center">
        Yeni Maç Ekle
      </div>
      <div className="text-lg text-gray-700 mb-8 text-center">
        Yeni bir maç ekleyebilir ve bu maçı web sayfasındaki liglerde
        gösterebilirsiniz.. Lütfen tüm alanları eksiksiz doldurunuz.
      </div>
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
        <AppSelect
          options={leagues}
          label={t('_matches.league')}
          name="league"
          error={errors.league}
          rules={{ required: true }}
          control={control}
          placeholder={t('_matches.league')}
        />
        <AppSelect
          options={teams}
          label={t('_matches.homeTeam')}
          name="homeTeam"
          // @ts-ignore
          error={errors.homeTeam}
          rules={{ required: true }}
          control={control}
          placeholder={t('_matches.homeTeam')}
        />
        <AppSelect
          options={teams}
          label={t('_matches.awayTeam')}
          name="awayTeam"
          // @ts-ignore
          error={errors.awayTeam}
          rules={{ required: true }}
          control={control}
          placeholder={t('_matches.awayTeam')}
        />
        <MaskInput
          label={t('_matches.matchDate')}
          mask="99/99/9999"
          maskPlaceholder="GG/AA/YYYY"
          name="matchDate"
          // @ts-ignore
          error={errors.matchDate}
          rules={{ required: true, pattern: datePattern }}
          register={register}
          placeholder={t('_matches.matchDate')}
        />
        <MaskInput
          label={t('_matches.matchTime')}
          mask="99:99"
          maskPlaceholder="HH:MM"
          name="matchTime"
          // @ts-ignore
          error={errors.matchTime}
          rules={{ required: true, pattern: timePattern }}
          register={register}
          placeholder={t('_matches.matchTime')}
        />
        <AppInput
          label={t('_matches.title')}
          name="title"
          // @ts-ignore
          error={errors.title}
          rules={{}}
          register={register}
          placeholder={t('_matches.title')}
        />
        <AppInput
          label={t('_matches.titleEn')}
          name="titleEn"
          // @ts-ignore
          error={errors.titleEn}
          rules={{}}
          register={register}
          placeholder={t('_matches.titleEn')}
        />
        <AppInput
          label={t('_matches.description')}
          name="description"
          // @ts-ignore
          error={errors.description}
          rules={{}}
          register={register}
          placeholder={t('_matches.description')}
        />
        <AppInput
          label={t('_matches.descriptionEn')}
          name="descriptionEn"
          // @ts-ignore
          error={errors.descriptionEn}
          rules={{}}
          register={register}
          placeholder={t('_matches.descriptionEn')}
        />
        <AppInput
          label="Ev Sahibi Skoru"
          name="homeScore"
          // @ts-ignore
          error={errors.homeScore}
          rules={{}}
          register={register}
          placeholder="Ev Sahibi Skoru"
        />
        <AppInput
          label="Deplasman Skoru"
          name="awayScore"
          // @ts-ignore
          error={errors.awayScore}
          rules={{}}
          register={register}
          placeholder="Deplasman Skoru"
        />
        {cover ? <img src={cover} className="w-full h-auto mb-5" /> : <></>}
        <AppFile
          error=""
          label="Maç Fotoğrafı"
          onCompleted={file => setCover(file.cdnUrl)}
          crop="auto"
        />
        <AppButton type="button" className={'mb-5'} onClick={generateCover}>
          Fotoğraf Üret
        </AppButton>
        <AppEditor
          label={t('_matches.homeContent')}
          name="homeContent"
          // @ts-ignore
          error={errors.homeContent}
          rules={{}}
          control={control}
          placeholder={t('_matches.homeContent')}
        />
        <AppEditor
          label={t('_matches.homeContentEn')}
          name="homeContentEn"
          // @ts-ignore
          error={errors.homeContentEn}
          rules={{}}
          control={control}
          placeholder={t('_matches.homeContentEn')}
        />
        <AppEditor
          label="Ev Sahibi Özeti"
          name="homeSummary"
          // @ts-ignore
          error={errors.homeSummary}
          rules={{}}
          control={control}
          placeholder="Ev Sahibi Özeti"
        />
        <AppEditor
          label="Ev Sahibi Özeti - İngilizce"
          name="homeSummaryEn"
          // @ts-ignore
          error={errors.homeSummary}
          rules={{}}
          control={control}
          placeholder="Ev Sahibi Özeti - İngilizce"
        />
        <AppEditor
          label={t('_matches.awayContent')}
          name="awayContent"
          // @ts-ignore
          error={errors.awayContent}
          rules={{}}
          control={control}
          placeholder={t('_matches.awayContent')}
        />
        <AppEditor
          label={t('_matches.awayContentEn')}
          name="awayContentEn"
          // @ts-ignore
          error={errors.awayContentEn}
          rules={{}}
          control={control}
          placeholder={t('_matches.awayContentEn')}
        />
        <AppEditor
          label="Deplasman Özeti"
          name="awaySummary"
          error={errors.awaySummary}
          rules={{}}
          control={control}
          placeholder="Deplasman Özeti"
        />
        <AppEditor
          label="Deplasman Özeti - İngilizce"
          name="awaySummaryEn"
          error={errors.awaySummary}
          rules={{}}
          control={control}
          placeholder="Deplasman Özeti - İngilizce"
        />
        <AppCheckbox
          label={t('_matches.isShowHome')}
          name="isShowHome"
          // @ts-ignore
          error={errors.isShowHome}
          rules={{}}
          register={register}
          placeholder={t('_matches.isShowHome')}
        />
        <AppInput
          label={t('_matches.homeOrder')}
          name="homeOrder"
          // @ts-ignore
          error={errors.homeOrder}
          rules={{}}
          register={register}
          placeholder={t('_matches.homeOrder')}
        />
        <AppCheckbox
          label={t('_matches.isShowSlider')}
          name="isShowSlider"
          // @ts-ignore
          error={errors.isShowSlider}
          rules={{}}
          register={register}
          placeholder={t('_matches.isShowSlider')}
        />
        <AppInput
          label={t('_matches.sliderOrder')}
          name="sliderOrder"
          // @ts-ignore
          error={errors.sliderOrder}
          rules={{}}
          register={register}
          placeholder={t('_matches.sliderOrder')}
        />
        <AppCheckbox
          label={t('_matches.isFeaturedMatch')}
          name="isFeaturedMatch"
          // @ts-ignore
          error={errors.isFeaturedMatch}
          rules={{}}
          register={register}
          placeholder={t('_matches.isFeaturedMatch')}
        />
        <AppInput
          label={t('_matches.featuredMatchOrder')}
          name="featuredMatchOrder"
          // @ts-ignore
          error={errors.featuredMatchOrder}
          rules={{}}
          register={register}
          placeholder={t('_matches.featuredMatchOrder')}
        />
        <AppSelect
          options={sites}
          label="Site"
          name="site"
          error={errors.site}
          control={control}
          placeholder="Site"
        />
        <AppEditor
          label="Site İçeriği"
          name="siteContent"
          // @ts-ignore
          error={errors.siteContent}
          rules={{}}
          control={control}
          placeholder="Site İçeriği"
        />
        <AppEditor
          label="Site İçeriği - İngilizce"
          name="siteContentEn"
          // @ts-ignore
          error={errors.siteContentEn}
          rules={{}}
          control={control}
          placeholder="Site İçeriği - İngilizce"
        />
        <AppCheckbox
          label="Bu maç yayına alınsın mı?"
          name="isPublished"
          // @ts-ignore
          error={errors.isPublished}
          rules={{}}
          register={register}
          placeholder="Bu maç yayına alınsın mı?"
        />
        <AppCheckbox
          label="Bu maç editörün seçimi mi?"
          name="isEditorChoice"
          // @ts-ignore
          error={errors.isEditorChoice}
          rules={{}}
          register={register}
          placeholder="Bu maç editörün seçimi mi?"
        />
        <div className={'flex gap-2'}>
          <AppButton
            type="button"
            className={'mb-5'}
            onClick={() => generateTwitterTodayMatch('tr')}
          >
            Twitter Günün Maçı Görseli Üret
          </AppButton>
          <AppButton
            type="button"
            className={'mb-5'}
            onClick={() => generateTwitterTodayMatch('en')}
          >
            Twitter Günün Maçı Görseli Üret - En
          </AppButton>
        </div>
        <div className={'flex gap-2'}>
          <AppButton
            type="button"
            className={'mb-5'}
            onClick={() => generateTwitterTodayComment('tr', true)}
          >
            Twitter Günün Açıklaması Görseli Üret (Ev Sahibi)
          </AppButton>
          <AppButton
            type="button"
            className={'mb-5'}
            onClick={() => generateTwitterTodayComment('en', true)}
          >
            Twitter Günün Açıklaması Görseli Üret (Ev Sahibi) - En
          </AppButton>
        </div>
        <div className={'flex gap-2'}>
          <AppButton
            type="button"
            className={'mb-5'}
            onClick={() => generateTwitterTodayComment('tr', false)}
          >
            Twitter Günün Açıklaması Görseli Üret (Deplasman)
          </AppButton>
          <AppButton
            type="button"
            className={'mb-5'}
            onClick={() => generateTwitterTodayComment('en', false)}
          >
            Twitter Günün Açıklaması Görseli Üret (Deplasman) - En
          </AppButton>
        </div>
        <div className={'flex gap-2'}>
          <AppButton
            type="button"
            className={'mb-5'}
            onClick={() => generateTwitterWhatHappened('tr', true)}
          >
            Twitter Ne Dedi Ne Oldu Görseli Üret (Ev Sahibi)
          </AppButton>
          <AppButton
            type="button"
            className={'mb-5'}
            onClick={() => generateTwitterWhatHappened('en', true)}
          >
            Twitter Ne Dedi Ne Oldu Görseli Üret (Ev Sahibi) - En
          </AppButton>
        </div>
        <div className={'flex gap-2'}>
          <AppButton
            type="button"
            className={'mb-5'}
            onClick={() => generateTwitterWhatHappened('tr', false)}
          >
            Twitter Ne Dedi Ne Oldu Görseli Üret (Deplasman)
          </AppButton>
          <AppButton
            type="button"
            className={'mb-5'}
            onClick={() => generateTwitterWhatHappened('en', false)}
          >
            Twitter Ne Dedi Ne Oldu Görseli Üret (Deplasman) - En
          </AppButton>
        </div>
        <AppButton type="submit" className="mt-5" isLoading={isLoading}>
          {t('_general.save')}
        </AppButton>
      </form>
    </main>
  );
}
