export default {
  _formErrorMessages: {
    required: '{{label}} alanı zorunludur.',
    minLength: '{{label}} alanını eksiksiz girmelisin.',
    pattern: '{{label}} alanına lütfen geçerli bir değer giriniz.'
  },
  _auth: {
    login: 'Giriş Yap',
    register: 'Kayıt Ol',
    forgotPassword: 'Şifremi Unuttum',
    resetPassword: 'Şifremi Sıfırla',
    logout: 'Çıkış Yap',
    email: 'E-Posta',
    password: 'Şifre',
    enterEmail: 'E-Posta Adresinizi Giriniz',
    enterPassword: 'Şifrenizi Giriniz',
    loginDescription:
      'Lütfen aşağıdaki butona tıklayarak Google ile giriş yapınız. Bu işlem yalnızca onaylanmış kullanıcılar için geçerlidir.',
    loginWithGoogle: 'Google ile Giriş Yap',
    loginAccessDenied:
      'Giriş yapmak için yetkiniz yok. Lütfen geçerli e-posta adresinizle giriş yapınız.'
  },
  _pageName: {
    home: 'Anasayfa',
    about: 'Hakkımızda',
    deploy: 'Yayınla',
    contact: 'İletişim',
    gallery: 'Galeri',
    slider: 'Slider',
    reference: 'Referanslar',
    project: 'Projeler'
  },
  _general: {
    image: 'Resim',
    save: 'Kaydet'
  },
  _deployPage: {
    title: 'Yaptığınız değişiklikleri yayınlayın',
    subtitle:
      'Bu sayfada yaptığınız değişiklikleri yayına alabilirsiniz. Yayınlanan değişikliklerinizi lütfen gözden geçirdikten sonra yayına alınız.',
    time: 'Yayınlanma Zamanı',
    minute: 'dakika',
    status: 'Durum',
    statusPending: 'Yayına Alınıyor',
    statusSuccess: 'Başarılı',
    deploying: 'Yayınlanıyor',
    deploy: 'Yayınla'
  },
  _contactPage: {
    title: 'İletişim Bilgileriniz',
    subtitle:
      'Lütfen iletişim bilgilerinizi ekleyiniz. Olmayan bir alanınız var ise, lütfen boş bırakınız.',
    phoneNumber: 'Telefon Numarası',
    email: 'E-Posta',
    address: 'İşyeri Adresi',
    footerText: 'Alt Alan İçeriği',
    facebookAccount: 'Facebook Hesabı',
    twitterAccount: 'Twitter Hesabı',

    instagramAccount: 'Instagram Hesabı',
    linkedinAccount: 'LinkedIn Hesabı',
    emailAccount: 'E-Posta Adresi',
    quotation: 'Alıntı',
    quotationHolder: 'Alıntı Sahibi'
  },
  _alerts: {
    successTitle: 'Başarılı',
    successSubtitle: 'Değişikler başarıyla kaydedildi.'
  },
  _teams: {
    addTeamTitle: 'Yeni Takım Ekle',
    addTeamDescription:
      'Yeni bir takım ekleyebilir ve bu takımı maçlarda seçebilirsiniz. Lütfen eklerken tüm alanları eksiksiz doldurunuz.',
    name: 'Takım İsmi',
    manager: 'Teknik Direktör'
  },
  _leagues: {
    name: 'Lig İsmi',
    weekName: 'Hafta Başlığı'
  },
  _matches: {
    league: 'Lig',
    homeTeam: 'Ev Sahibi Takım',
    awayTeam: 'Deplasman Takımı',
    matchDate: 'Maç Tarihi',
    name: 'Maç İsmi',
    matchTime: 'Maç Saati',
    homeContent: 'Ev Sahibi Açıklaması',
    awayContent: 'Deplasman Açıklaması',
    isShowHome: "Anasayfa'da gösterilsin mi?",
    title: 'Maç Başlığı',
    description: 'Maç Açıklaması',
    isShowSlider: "Slider'a eklensin mi?",
    isFeaturedMatch: 'Bu maç önerilen maç mı?',
    homeOrder: 'Anasayfa Sırası',
    sliderOrder: 'Slider Sırası',
    featuredMatchOrder: 'Önerilen Maç Sırası'
  }
};
