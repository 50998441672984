export default async function deployApi(lang) {
  const API_URL =
    lang === 'en'
      ? process.env.REACT_APP_DEPLOY_EN_HOOKS_URL
      : process.env.REACT_APP_DEPLOY_HOOKS_URL;
  const response = await fetch(API_URL, {
    method: 'GET'
  });

  return response;
}
