import { AppButton, AppTable } from 'components';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import api from 'services/api';

const LeagueList = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [data, setData] = useState([]);
  const [page] = useState(
    searchParams.get('page') ? Number(searchParams.get('page')) : 1
  );
  const titles = [
    'LİG ADI',
    'EV SAHİBİ',
    'DEPLASMAN',
    'MAÇ TARİHİ',
    'YAYINDA MI?'
  ];

  const onDelete = async id => {
    api.match.delete(id).then(() => {
      navigate(0);
    });
  };

  const onSendArchive = async id => {
    api.match.changeArchive(id, true).then(() => {
      navigate(0);
    });
  };

  useEffect(() => {
    api.match
      .list(page)
      .then(async res => {
        setData(res.data);
      })
      .catch(error => console.log('error', error));
  }, []);

  return (
    <main className="w-full">
      <AppButton
        className="mb-5 ml-auto"
        isFullWidth={false}
        onClick={() => navigate('/match/add')}
      >
        Yeni Maç Ekle
      </AppButton>
      <AppTable
        titles={titles}
        rows={data}
        isEdit={true}
        isDelete={true}
        pageKey="match"
        pageNumber={page}
        onDelete={onDelete}
        isSendArchive={true}
        onSendArchive={onSendArchive}
        pageItems={20}
        hideRows="__v,_id,slug,createdDate,homeContent,awayContent,description,cover,awayScore,homeScore,updatedAt,isFeaturedMatch,isShowSlider,title,homeHighlight,awayHighlight,createdAt,archived,isShowHome,titleEn,homeHighlightEn,awayHighlightEn,homeContentEn,awayContentEn,descriptionEn,slugEn,nameEn,homeOrder,sliderOrder,featuredMatchOrder,site,siteContent,siteContentEn,homeSummary,awaySummary,homeSummaryEn,awaySummaryEn,isEditorChoice"
      />
    </main>
  );
};

export default LeagueList;
