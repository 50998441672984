export const countryApi = api => ({
  create(
    name,
    nameEn,
    code,
    site_1,
    site_2,
    site_3,
    site_4,
    site_5,
    site_6,
    site_7,
    site_8,
    site_9,
    site_10
  ) {
    return api.post('/country/create', {
      name,
      nameEn,
      code,
      site_1,
      site_2,
      site_3,
      site_4,
      site_5,
      site_6,
      site_7,
      site_8,
      site_9,
      site_10
    });
  },
  list(page) {
    return api.get(`/country/list/${page}`);
  },
  get(id) {
    return api.get(`/country/${id}`);
  },
  update(
    name,
    nameEn,
    code,
    site_1,
    site_2,
    site_3,
    site_4,
    site_5,
    site_6,
    site_7,
    site_8,
    site_9,
    site_10,
    id
  ) {
    return api.post(`/country/update/${id}`, {
      name,
      nameEn,
      code,
      site_1,
      site_2,
      site_3,
      site_4,
      site_5,
      site_6,
      site_7,
      site_8,
      site_9,
      site_10
    });
  },
  delete(id) {
    return api.delete(`/country/delete/${id}`);
  }
});
